<template lang="pug">
    section#equipe
        .wrapper
            h3 Lorem ipsum dolor
            ul
                li(v-for="i in 4")
                    .img
                        img(:src="require('@images/sobrenos/equipe/thumb-equipe.jpg')")
                    .info
                        h4 Name Here
                        span CEO
                        p Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
        
</template>

<script>
export default {
    name: "section-equipe",
}
</script>

<style lang="stylus" scoped src="./Equipe.styl"></style>