<template lang="pug">
	main#sobreNos
		BannerImagem(:image="conteudo.banner")
		TituloPagina(:titulo="conteudo.titulo")
		NossaHistoria
		// Equipe
		Parceiros
		Instituto
		AsideVideo(v-if="$store.state.asideVideo != null")
</template>

<script>
import BannerImagem from '@components/BannerImagem/BannerImagem'
import TituloPagina from '@components/TituloPagina/TituloPagina'
import NossaHistoria from '@sections/SobreNos/NossaHistoria/NossaHistoria'
import Equipe from '@sections/SobreNos/Equipe/Equipe'
import Parceiros from '@sections/SobreNos/Parceiros/Parceiros'
import Instituto from '@sections/SobreNos/Instituto/Instituto'
import AsideVideo from '@components/AsideVideo/AsideVideo'


export default {
	name: "view-sobre-nos",
	components: {
		BannerImagem,
		TituloPagina,
		NossaHistoria,
		Equipe,
		Parceiros,
		Instituto,
		AsideVideo
	},
	metaInfo() {
		return {
			title: 'Sobre Nós | CA2A',
			meta: [
				{ name: 'description', content: 'A casa da segunda casa.' },
			],
		}
	},
	data() {
		return {
			conteudo: {
				banner: require('@images/backgrounds/sobre.jpg'),
				titulo: {
					// textoMenor: "um pouco mais sobre ",
					textoMaior: "Nossa História"
				}
			}
		}
	}
}
</script>

<style lang="stylus" scoped src="./SobreNos.styl"></style>