<template lang="pug">
    section#instituto
        .wrapper
            .instituto
                img(:src="require('@images/sobrenos/instituto-morrodosanjos.png')")
            p
                |Um trabalho feito com dedicação, cuidado e amor em cada atendimento.
                strong Projeto de responsabilidade social da CA2A
                |Voltado para crianças portadoras de necessidades especiais, como Autismo e Síndrome de Down
                strong Uso de equoterapia para melhorar a qualidade de vida
                |Localizado em Bandeirantes-PR
            span Para saber mais, siga no Instagram: 
            a(href="https://www.instagram.com/institutomorrodosanjos/", target="_blank")
                strong @institutomorrodosanjos
</template>

<script>
export default {
    name: "section-instituto",
}
</script>

<style lang="stylus" scoped src="./Instituto.styl"></style>