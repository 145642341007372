<template lang="pug">
    section#parceiros
        h4 Parceiros
        ul
            li
                img(:src="require('@images/sobrenos/gpac.png')")
            li
                img(:src="require('@images/sobrenos/realsmart.png')")
            li
                img(:src="require('@images/sobrenos/rio-verde.png')")
            li
                img(:src="require('@images/sobrenos/grupo-massa.png')")
            li
                img(:src="require('@images/sobrenos/nqm.png')")
</template>

<script>
export default {
    name: "section-parceiros",
}
</script>

<style lang="stylus" scoped src="./Parceiros.styl"></style>