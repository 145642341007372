<template lang="pug">
    section#nossaHistoria
        .wrapper
            p
                |Uma empresa que nasceu para trazer a seus clientes um novo jeito de comprar imóvel. Moderna e inovadora, trabalha com uma plataforma de serviços e produtos que estão revolucionando o segmento imobiliário. 
                br
                br
                |A CA2A segue os princípios ESG. Em poucas palavras, significa ser responsável com o meio ambiente, cuidar e respeitar seus colaboradores e praticar uma governança correta e transparente. Com gestão profissional, a empresa vem desenvolvendo projetos e imóveis diferenciados, direcionados principalmente a dois tipos de público.
            
            h4 1. QUEM QUER COMPRAR UMA SEGUNDA CASA

            p
                |Quem tem uma segunda casa sabe os custos e cuidados que ela exige o ano inteiro. Mesmo se usada só de vez em quando. Com a CA2A, é possível ter essa segunda casa sem se preocupar com manutenção, imprevistos ou reformas. E com custos fixos muito menores. E mais: esse imóvel é de altíssimo padrão, tem estrutura completa de lazer e serviços pra você não fazer nada além de aproveitar momentos de lazer. 

            h4 2. QUEM QUER INVESTIR PARA RENTABILIZAR

            p
                strong A CA2A tem imóveis ideais para esse tipo de público:
                br 
                br
                |- Imóveis de alto padrão com estrutura completa de lazer, e localização privilegiada.
                br
                br
                |- Baixíssima sazonalidade devido aos atrativos especialmente trabalhados em cada empreendimento. 
                br
                br
                |- Sistema de gestão de ocupação com absoluta transparência e competência. 
                br
                br
                |- Qualidade de produto atestada pelos empreendimentos já entregues. 
                br
                br
                |- Imóveis com escritura pública e matrícula: patrimônio familiar para as próximas gerações.
            
            
            .thumb-video(@click="$store.commit('setAsideVideo', video)")
                img(:src="require('@images/home/thumb-video.jpg')")
                button
                    FontAwesomeIcon(:icon="icons.faCirclePlay")
            //- ul
            //-     li
            //-         .icon
            //-             img(:src="require('@images/sobrenos/missao.png')")
            //-         .box    
            //-             h5 Missão
            //-             p
            //-                 |Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quam ex, luctus at turpis ac, congue mattis mi. Donec bibendum mi eu cursus cursus. Ut maximus nulla dapibus vulputate condimentum.
            //-     li
            //-         .icon
            //-             img(:src="require('@images/sobrenos/visao.png')")
            //-         .box    
            //-             h5 Visão
            //-             p
            //-                 |Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quam ex, luctus at turpis ac, congue mattis mi. Donec bibendum mi eu cursus cursus. Ut maximus nulla dapibus vulputate condimentum.
            //-     li
            //-         .icon
            //-             img(:src="require('@images/sobrenos/valores.png')")
            //-         .box    
            //-             h5 Valores
            //-             p
            //-                 |Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quam ex, luctus at turpis ac, congue mattis mi. Donec bibendum mi eu cursus cursus. Ut maximus nulla dapibus vulputate condimentum.
</template>

<script>
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons'

export default {
    name: "section-nossa-historia",
    data() {
        return {
            icons: {
                faCirclePlay
            },
            video: require('@assets/videos/ca2a_manifesto.mp4')
        }
    }
}
</script>

<style lang="stylus" scoped src="./NossaHistoria.styl"></style>